import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import fourOFourPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

import {Typography} from "@material-ui/core";

const dashboardRoutes = [];

class FourOFourPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          brand="Nari"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white"
          }}
          {...rest}
        />
        <Parallax small filter image={require("assets/img/hero/mtatsminda.jpg")} />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
              <br/>
            <Typography variant="h3" component="h1">404 - Page not found</Typography>
              <br/>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(fourOFourPageStyle)(FourOFourPage);
